.commentsSection {
    background: var(--dark-grey);
    color: var(--text);
    margin: 0 auto;
    width: 100%;
    padding: .5rem;
  }
.commentsTitle{
  margin: 0 0 .5rem 0;
  padding: 0;
}
  .comments {
    width: 100%;
    height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }