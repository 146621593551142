.albumPage{
  margin: 0 3rem;
}
.album {
  max-width: 500px;
  background: var(--dark-grey);
  box-shadow: 2px 2px 10px 0px  ;
  border-radius: 3px;
  margin: 5rem auto 2rem;
}
.controls >button {
  position: fixed;
  top: 52px;
  background-color: transparent;
  height: 100vh;
  width: max(calc(50vw - 250px), 3rem);
  color: var(--text);
  font-size: 3rem;
  border: none;
  cursor: pointer;
}
.controls>button:first-child {
  left: 0;
}
.controls>button:last-child {
  right: 0;
  top: 52px;
}
.albumTitle {
  color: var(--text);
  font-size: 1.25rem;
  padding: .75rem;
  font-weight: 900;
}
.albumDescription {
  color: var(--text);
  padding: 1rem;
}
.albumImage {
  width: 100%;
  display: block;
}
.albumPage {
  margin-top: 80px;
}
@media (max-width: 600px ) {
  .controls >button {
    font-size: 1.5rem;
  }
}