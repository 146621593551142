.box {
  margin: 20px auto;
  border-radius: 2px;
  display: grid;
  background: var(--dark-grey);
  box-shadow: 2px 2px 10px 0px  ;
  margin-bottom: 30px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  width: 300px;
  grid-template-areas:
    'title title'
    'picture picture'
    'up down';
  grid-column-gap: 0px;
}
.title {
  padding: 10px 15px;
  grid-area: title;
  font-weight: bold;
  font-size: 1rem;
  color: var(--text);
}
.container {
  display: flex;
  margin: 0px;
  align-items: center;
  border: none;
  cursor: pointer;
  justify-content: center;
  grid-area: picture;
  overflow: hidden;
}
.picture {
  width: 100%;
}

.upvote {
  grid-area: up;
  margin: 0 0 10px 20px;
  color: white;
  justify-self: center;
}
.icon {
  display: inline-flex;
  align-self: center;
  padding-right: 5px;
}

.stats{
  display: flex;
  justify-content: flex-end;
  grid-area: down;
  grid-column: 1/3;
  font-size: .75rem;
  margin: 10px 15px;
}
.statIcon:first-child{
  margin-right: auto;
  margin-left: 0px;  
}
.statIcon {
  display: flex;
  align-items: center;
  color: var(--text);
  margin-left: 5px;
}
.statIcon svg{
  margin-right: 3px;
}