.logo {
    display: flex;
  }
  .link {
    font-size: 1.5rem;
    text-decoration: none;
    color: var(--text);
    font-weight: bold;
  }
  .navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    padding: 10px calc(5% + 10px) 10px calc(5% + 10px);
  
    background: var(--dark-grey);
  
    justify-content: space-between;
  }
  .dropdown {
    margin: 0 5px 0 10px;
  }
  .button {
    height: 32px;
    border-radius: 0 5px 5px 0;
    margin-left: 10px;
    background-color: var(--light-grey);
  }
  
  #page1 {
    margin: 0 0 0 20px;
  }
  /*dropdown*/
  /* Reset Select */
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: var(--grey);
    background-image: none;
  }
  /* Remove IE arrow */
  select::-ms-expand {
    display: none;
  }
  /* Custom Select */
  .select {
    position: relative;
    display: flex;
    width: 10em;
    height: 2em;
    line-height: 2;
    overflow: hidden;
    border-radius: 0.25em;
  }
  select {
    flex: 1;
    padding: 0 0.5em;
    color: #fff;
    cursor: pointer;
  }
  /* Arrow */
  .select::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 1em;
    background: var(--light-grey);
    cursor: pointer;
    pointer-events: none;
    -webkit-transition: 0.25s all ease;
    -o-transition: 0.25s all ease;
    transition: 0.25s all ease;
  }
  /* Transition */
  .select:hover::after {
    color: #fff;
  }
  .pageSelector {
    display: flex;
  }
  @media (max-width: 600px ) {
    .logo{
      display: none;
    }
    .navbar{
      justify-content: center;
    }
  }