* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
:root{
  --black: #06101A;
  --dark-grey: #1F2933;
  --grey: #323F4B;
  --light-grey: #52606D;
  --text: #CBD2D9;
}
body {
  background: var(--black);
}
.main {
  margin-top: 50px;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  
}


/* masonry */

.my-masonry-grid {
  margin: 0 auto;
  width: 90%;
  padding: 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.my-masonry-grid_column {
  background-clip: padding-box;
}